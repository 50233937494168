import React from "react"

import Layout from "../components/layout"
import Features from "../components/features";
import SEO from "../components/seo";

class FeaturesIndex extends React.Component {




  render() {


    return (
      <Layout>
        <SEO title="Features"
        description={'Our employee handbook software comes with all this built in.'}
        />
        <Features />
      </Layout>
    )
  }
}

export default FeaturesIndex
